import { DateTime } from "luxon";
import { castDateTime } from "./datetime";

export const dateFormat = "dd.MM.yy";
export const dateTimeFormat = "dd.MM.yy T";
export const dateTimeFormatWithoutYear = "dd.MM. T";
export const timeFormat = "T";


export function formatDate(date: DateTime | string) {
    return castDateTime(date).toFormat(dateFormat, { locale: "de" });
}

export function formatDayOfWeekAbbreviated(date: DateTime | string) {
    return castDateTime(date).toFormat("ccc", { locale: "de" });
}

export function formatDateTime(date: DateTime | string) {
    return castDateTime(date).toFormat(dateTimeFormat, { locale: "de" });
}

export function formatDateRange(startsOn: DateTime | string, endsOn: DateTime | string) {
    const startsOnObj = castDateTime(startsOn);
    const endsOnObj = castDateTime(endsOn);
    return `${startsOnObj.toFormat(dateFormat)} → ${endsOnObj.toFormat(dateFormat)}`;
}

export function formatWeekNumber(date: DateTime | string) {
    return castDateTime(date).toFormat("W", { locale: "de" });
}
