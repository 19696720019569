import * as React from "react";
import { useState } from "react";

export function Time() {
    const [time, setTime] = useState(new Date().toLocaleTimeString());

    React.useEffect(() => {
        const interval = setInterval(() => {
            setTime(new Date().toLocaleTimeString());
        }, 1000);
        return () => clearInterval(interval);
    }, []);

    return (<div>
        <div className="opacity-50">Aktuelle Uhrzeit</div>
        <div className="fs-3 fw-bold">{time}</div>
    </div>);
}
