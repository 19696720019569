import * as React from "react";
import { AuthenticationContext } from "../authentication/Authentication";
import { useContext, useEffect, useMemo, useState } from "react";
import { checkIn, checkOut, endBreak, startBreak } from "../api";

type EmployeeState =
    "checked_in"
    | "checking_in"
    | "checked_out"
    | "checking_out"
    | "on_break"
    | "starting_break"
    | "ending_break";

export function TimeTracker() {
    const { accessToken, employeeInfo, onLogout } = useContext(AuthenticationContext);
    const [employeeState, setEmployeeState] = useState<EmployeeState>("checked_out");
    const [tokenUsed, setTokenUsed] = useState(false);

    useEffect(() => {
        setEmployeeState(employeeInfo.ettStatus as EmployeeState);
    }, [employeeInfo.ettStatus]);

    useEffect(() => {
        if (employeeState === "checking_in") {
            checkIn(accessToken).then(newState => setEmployeeState(newState));

        } else if (employeeState === "checking_out") {
            checkOut(accessToken).then(newState => setEmployeeState(newState));

        } else if (employeeState === "starting_break") {
            startBreak(accessToken).then(newState => setEmployeeState(newState));

        } else if (employeeState === "ending_break") {
            endBreak(accessToken).then(newState => setEmployeeState(newState));
        }
    }, [employeeState, accessToken]);

    const transition = (newState: EmployeeState) => () => {
        setEmployeeState(newState);
        setTokenUsed(true);
        setTimeout(() => onLogout(), 2000);
    };

    const humanState = useMemo(() => {
        switch (employeeState) {
            case "checked_in":
                return "eingecheckt";
            case "checked_out":
                return "ausgecheckt";
            case "on_break":
                return "in der Pause";
            default:
                return "unbekannt";
        }
    }, [employeeState]);

    const [resultMessage, cheerMessage] = useMemo(() => {
        switch (employeeState) {
            case "checked_in":
                return ["Du bist jetzt eingecheckt", "Schön, dass Du da bist!"];
            case "checked_out":
                return ["Du bist jetzt ausgecheckt", "Hab einen schönen Feierabend!"];
            case "on_break":
                return ["Du bist jetzt in der Pause", "Erhol Dich gut!"];
            default:
                return ["unbekannt", "unbekannt"];
        }
    }, [employeeState]);

    return (
        <>
            <div>
                {tokenUsed ? (<div>
                    <div className="mb-3 fs-4">{cheerMessage}</div>
                    <div className="mb-3">{resultMessage}</div>
                    <div className="mb-3">Du wirst nach 2 Sekunden automatisch abgemeldet.</div>
                </div>) : (<>
                        <div className="mb-3 fs-2">
                            Hallo <strong>{employeeInfo.name}</strong>, Du bist
                            aktuell <strong>{humanState}</strong>.
                        </div>

                        <div className="mb-3 w-100 d-grid gap-2 w-25">
                            {employeeState === "checked_out" && (
                                <button className="btn btn-light btn-lg d-block"
                                        onClick={transition("checking_in")}>Einchecken</button>
                            )}
                            {employeeState === "checked_in" && (
                                <>
                                    <button className="btn btn-danger btn-lg d-block"
                                            onClick={transition("checking_out")}>Auschecken
                                    </button>
                                    <button className="btn btn-secondary btn-lg d-block"
                                            onClick={transition("starting_break")}>Pause beginnen
                                    </button>
                                </>
                            )}
                            {employeeState === "on_break" && (
                                <button className="btn btn-secondary btn-lg d-block"
                                        onClick={transition("ending_break")}>Pause
                                    beenden</button>
                            )}

                        </div>
                    </>
                )}
            </div>
        </>
    );
}
