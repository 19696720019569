import * as React from "react";
import { useMemo } from "react";
import ErrorBoundary from "../../lib/errors/ErrorsBoundary";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import { Authentication } from "./authentication/Authentication";
import { TimeTracker } from "./time-tracker/TimeTracker";
import { Layout } from "./layout/Layout";
import { DeviceContext } from "./DeviceContext";

type WorkHubProps = {
    deviceId: string;
}

export default function WorkHub(props: WorkHubProps) {
    const { deviceId } = props;

    const router = useMemo(() => createBrowserRouter(
        [{ path: "/", element: <TimeTracker /> }],
        { basename: `/workhub/ui/${deviceId}` }
    ), [deviceId]);

    return (
        <React.StrictMode>
            <ErrorBoundary>
                <DeviceContext.Provider value={{ deviceId }}>
                    <Layout>
                        <Authentication>
                            <RouterProvider router={router} />
                        </Authentication>
                    </Layout>
                </DeviceContext.Provider>
            </ErrorBoundary>
        </React.StrictMode>
    );
}
