import { useEffect, useRef } from "react";
import QrScanner from "qr-scanner";

type UseTokenScannerProps = {
    onAuthToken: (token: string) => void;
}

export function useAuthTokenScanner(props: UseTokenScannerProps) {
    const { onAuthToken } = props;
    const videoRef = useRef<HTMLVideoElement>(null);

    useEffect(() => {
        if (videoRef.current) {
            const qrScanner = new QrScanner(videoRef.current, result => {
                onAuthToken(result.data);
            }, {
                highlightScanRegion: true,
                highlightCodeOutline: true
            });

            qrScanner
                .setCamera("user")
                .then(() => {
                    return qrScanner.start();
                }).catch((error: Error) => {
                    console.warn("Error while starting QR scanner: ", error.message);
                }
            );

            return () => {
                qrScanner.destroy();
            };
        }
    }, [videoRef.current, onAuthToken]);

    return { videoRef };
}
