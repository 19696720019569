import * as React from "react";
import { useAuthTokenScanner } from "./useAuthTokenScanner";
import { createContext, useCallback, useEffect, useState } from "react";
import { EmployeeInfo, tokenExpirationGracePeriod, useAccessTokenExchange } from "./useAccessTokenExchange";

export const AuthenticationContext = createContext({
    accessToken: null as string | null,
    employeeInfo: null as EmployeeInfo | null,
    onLogout: (() => null) as () => unknown
});

type AuthenticationProps = {
    children?: React.ReactNode;
};

// For development only:
const initialAuthToken = null;
// const initialAuthToken = "6bafbbcb2af483620f10e1d84de98cde4a388dd8"; // debug in dev
const initialAccessToken = null;
const initialEmployeeInfo = null;
const initialExpiresIn = null;

export function Authentication(props: AuthenticationProps) {
    const { children } = props;

    const [authToken, setAuthToken] = useState<string | null>(initialAuthToken);
    const [accessToken, setAccessToken] = useState<string | null>(initialAccessToken);
    const [accessTokenExpiresIn, setAccessTokenExpiresIn] = useState<number | null>(initialExpiresIn);
    const [employeeInfo, setEmployeInfo] = useState<EmployeeInfo | null>(initialEmployeeInfo);
    const [exchangeError, setExchangeError] = useState<string | null>(null);
    const [secondsLeft, setSecondsLeft] = useState<number | null>(null);

    const { videoRef } = useAuthTokenScanner({
        onAuthToken: (token) => {
            console.log("Token detected:", token);
            if (!authToken && token) {
                console.log("New token set");
                setAuthToken(token);
            } else {
                console.log("Detected token not set, because it was already set before");
            }
        }
    });

    const onExchangeSuccess = useCallback(({ accessToken, employeeInfo, expiresIn }: {
        accessToken: string,
        employeeInfo: EmployeeInfo,
        expiresIn: number
    }) => {
        console.log("Access token exchange successful", accessToken);
        setAccessToken(accessToken);
        setEmployeInfo(employeeInfo);
        setAccessTokenExpiresIn(expiresIn);
        setSecondsLeft(30); // Will be overridden in the next effect

    }, [setAccessToken, setEmployeInfo, setAccessTokenExpiresIn]);

    const onExchangeError = useCallback((error: string) => {
        console.error("Access token exchange failed:", error);
        setExchangeError(error);
        setAuthToken(null);
    }, [setExchangeError]);

    const onLogout = useCallback(() => {
        setAccessToken(null);
        setAccessTokenExpiresIn(null);
        setAuthToken(null);
        document.location.reload();
    }, [setAccessToken, setAccessTokenExpiresIn, setAuthToken]);

    const onAccessTokenExpired = useCallback(() => {
        console.log("Access token expired");
        onLogout();
    }, [onLogout]);

    useAccessTokenExchange(authToken, {
        onSuccess: onExchangeSuccess,
        onError: onExchangeError,
        onExpired: onAccessTokenExpired
    });

    useEffect(() => {
        const expiresAt = Date.now() + (accessTokenExpiresIn || 0) * 1000 * tokenExpirationGracePeriod;
        const timeout = setInterval(() => {
            const secondsLeft = Math.max(0, Math.round((expiresAt - Date.now()) / 1000));
            setSecondsLeft(secondsLeft ? secondsLeft : null);
        }, 1000);
        return () => clearInterval(timeout);
    }, [accessTokenExpiresIn]);


    useEffect(() => {
        if (exchangeError) {
            const timeout = setTimeout(() => {
                document.location.reload();
            }, 2500);
            return () => clearTimeout(timeout);
        }
    }, [exchangeError]);

    return (
        <>
            {exchangeError && (
                <div
                    className="px-3 py-3 text-center bg-danger text-white rounded-2 position-absolute w-100"
                    style={{ left: 0, top: 0 }}>
                    <h1 className="" role="alert">
                        Anmeldung fehlgeschlagen. Bitte überprüfe Deine Code-Karte.
                    </h1>
                </div>
            )}

            {!exchangeError && !authToken &&
                <div className="h-100 w-100 d-flex flex-column align-items-center justify-content-center gap-4">
                    <h1 className="text-center">
                        Bitte halte deine Brix-Code-Karte vor die Kamera.
                    </h1>
                    <div>
                        <div className="rounded-3 overflow-hidden border border-2 border-white"
                             style={{ width: "400px", height: "225px" }}>
                            <video
                                ref={videoRef}
                                width="100%"
                                style={{ aspectRatio: "1920/1080", maxWidth: "400px", maxHeight: "100%" }}
                                className="border border-3 border-primary bg-primary"
                            />
                        </div>
                    </div>
                    <div>
                        Kein Video?{" "}
                        <button className="btn btn-outline-secondary" onClick={() => document.location.reload()}>
                            Neustart
                        </button>
                    </div>
                </div>}

            {accessToken && <AuthenticationContext.Provider value={{ accessToken, employeeInfo, onLogout }}>
                <div className="mb-3 flex d-flex flex-column justify-content-center" style={{ flex: "100% 1 1" }}>
                    {children}
                </div>
            </AuthenticationContext.Provider>}

            {secondsLeft !== null && (
                <div
                    className="text-center w-100 p-4 border-top border-3 border-white"
                    style={{ zIndex: 10 }}>
                    <div className="mb-2">
                        Automatische Abmeldung in <span className="text-nowrap">{secondsLeft} Sekunden.</span>
                    </div>
                    <div className="d-grid w-100">
                        <button className="btn btn-outline-light" onClick={onAccessTokenExpired}>Abmelden</button>
                    </div>
                </div>
            )}
        </>
    );
}
