import { Brand } from "./Brand";
import * as React from "react";
import { Time } from "./Time";

type LayoutProps = {
    children?: React.ReactNode;
};

export function Layout(props: LayoutProps) {
    const { children } = props;

    return (
        <div className="h-100 text-white px-4 py-4 d-flex flex-column gap-4">
            <header className="d-flex gap-3 justify-content-between align-items-end">
                <Brand />
                <Time />
            </header>
            <main className="d-flex flex-column justify-content-center" style={{ flex: "100% 1 1" }}>
                {children}
            </main>
        </div>
    );
}
