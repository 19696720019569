import * as React from "react";

export function Brand() {
    return (<div>
        <svg width="102" height="27" viewBox="0 0 102 27" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="4.18878" width="21.5424" height="6.6" rx="3.3" fill="#EEF2FF" />
            <rect y="9.9" width="11.968" height="6.6" rx="3.3" fill="#EEF2FF" />
            <rect x="13.1648" y="9.9" width="16.7552" height="6.6" rx="3.3" fill="#EEF2FF" />
            <rect x="4.18878" y="19.8" width="14.3616" height="6.6" rx="3.3" fill="#EEF2FF" />
            <ellipse cx="22.7392" cy="23.1" rx="2.992" ry="3.3" fill="#EEF2FF" />
            <path
                d="M48.66 16.52C48.66 17.7867 48.2866 18.72 47.54 19.32C46.8067 19.9067 45.6467 20.2 44.06 20.2H38.88V7.66H44.06C45.5 7.66 46.52 7.91333 47.12 8.42C47.7333 8.91333 48.04 9.74666 48.04 10.92C48.04 11.6667 47.94 12.24 47.74 12.64C47.54 13.0267 47.14 13.3267 46.54 13.54C47.26 13.6867 47.7933 14.0067 48.14 14.5C48.4866 14.9933 48.66 15.6667 48.66 16.52ZM45.18 11.38C45.18 10.94 45.0933 10.6333 44.92 10.46C44.76 10.2867 44.4733 10.2 44.06 10.2H41.98V12.74H44.06C44.4733 12.74 44.76 12.64 44.92 12.44C45.0933 12.2267 45.18 11.8733 45.18 11.38ZM45.66 16.32C45.66 15.84 45.54 15.5067 45.3 15.32C45.06 15.12 44.6466 15.02 44.06 15.02H41.98V17.56H44.06C44.6466 17.56 45.06 17.4667 45.3 17.28C45.54 17.0933 45.66 16.7733 45.66 16.32ZM53.1128 16.16V20.2H50.0128V7.66H54.5928C56.5261 7.66 57.8995 7.98666 58.7128 8.64C59.5261 9.29333 59.9328 10.38 59.9328 11.9C59.9328 12.9267 59.7128 13.7667 59.2728 14.42C58.8461 15.06 58.1928 15.5267 57.3128 15.82L60.3728 20.2H56.6528L54.0528 16.16H53.1128ZM56.7928 11.9C56.7928 11.3667 56.6328 10.9933 56.3128 10.78C55.9928 10.5667 55.4195 10.46 54.5928 10.46H53.1128V13.66H54.5928C55.4195 13.66 55.9928 13.5333 56.3128 13.28C56.6328 13.0133 56.7928 12.5533 56.7928 11.9ZM64.6558 7.66V20.2H61.5558V7.66H64.6558ZM73.2153 13.68L77.3753 20.2H73.5953L71.1553 16.2L68.8153 20.2H65.4153L69.5353 13.8L65.6553 7.66H69.4353L71.5353 11.24L73.6553 7.66H76.9553L73.2153 13.68ZM80.2592 20.2H78.5592V7.66H80.2592V13.06H86.8192V7.66H88.5192V20.2H86.8192V14.62H80.2592V20.2ZM93.0912 15.7V20.2H91.3912V7.66H95.6512C97.4246 7.66 98.6912 7.96666 99.4512 8.58C100.225 9.18 100.611 10.18 100.611 11.58C100.611 12.6733 100.365 13.5467 99.8712 14.2C99.3912 14.84 98.6579 15.2733 97.6712 15.5L100.951 20.2H98.9112L95.8712 15.7H93.0912ZM98.8912 11.58C98.8912 10.7267 98.6446 10.12 98.1512 9.76C97.6579 9.38666 96.8246 9.2 95.6512 9.2H93.0912V14.2H95.6512C96.7979 14.2 97.6246 13.9933 98.1312 13.58C98.6379 13.1667 98.8912 12.5 98.8912 11.58Z"
                fill="#EEF2FF" />
        </svg>
    </div>);
}
