import * as React from "react";
import { ReportDay } from "./report-grid/types";
import { GridBody } from "./report-grid/GridBody";
import { GridHeader } from "./report-grid/GridHeader";

type EttReportGridProps = {
    daysByWeeks: Record<string, ReportDay[]>;
    expandable: boolean;
}

export function EttReportGrid(_props: EttReportGridProps) {
    const { daysByWeeks, expandable = true } = _props;

    return (
        <div className="EttReportGrid">
            <GridHeader expandable={expandable} />
            <GridBody daysByWeeks={daysByWeeks} expandable={expandable} />
        </div>
    );
}
