const headers = {
    "Content-Type": "application/json",
    "Accept": "application/json"
};

export type RawAccessTokenResponse = {
    data?: {
        access_token: string;
        expires_in: number;
        employee: {
            name: string;
            ett_status: string;
        }
    };
    error?: string;
};

export type AccessTokenResponse = {
    accessToken?: string;
    expiresIn?: number;
    employee?: {
        name: string;
        ettStatus: string;
    };
    error?: string;
};

export async function fetchAccessToken(authToken: string, deviceId: string, signal?: AbortSignal): Promise<AccessTokenResponse> {
    const options = {
        method: "POST",
        signal,
        headers,
        body: JSON.stringify({ auth_token: authToken, device_id: deviceId })
    };
    return fetch(`/workhub/api/access_tokens`, options)
        .then(response => response.json())
        .then((json: RawAccessTokenResponse) => {
                if (json.error) {
                    return { error: json.error };
                } else if (json.data) {
                    return {
                        accessToken: json.data.access_token,
                        expiresIn: json.data.expires_in,
                        employee: {
                            name: json.data.employee.name,
                            ettStatus: json.data.employee.ett_status
                        }
                    };
                } else {
                    return { error: "Unexpected response format from server, missing data or error." };
                }
            }
        );
}

async function transition(transition: EmployeeStateTransition, accessToken: string, signal?: AbortSignal): Promise<DefinitiveEmployeeState> {
    return new Promise((resolve, reject) => {
        const options = {
            method: "POST",
            signal,
            headers: {
                ...headers,
                "Authorization": `Bearer ${accessToken}`
            },
            body: JSON.stringify({ transition })
        };
        return fetch(`/workhub/api/events`, options)
            .then(response => response.json())
            .then(json => {
                if (json.error) {
                    reject(json.error);
                } else {
                    resolve(json.data.ett_status);
                }
            });
    });
}

export type DefinitiveEmployeeState = "checked_in" | "checked_out" | "on_break";
export type EmployeeStateTransition = "check_in" | "check_out" | "start_break" | "end_break";

export function checkIn(accessToken: string, signal?: AbortSignal): Promise<DefinitiveEmployeeState> {
    return transition("check_in", accessToken, signal);
}

export function checkOut(accessToken: string, signal?: AbortSignal): Promise<DefinitiveEmployeeState> {
    return transition("check_out", accessToken, signal);
}

export function startBreak(accessToken: string, signal?: AbortSignal): Promise<DefinitiveEmployeeState> {
    return transition("start_break", accessToken, signal);
}

export function endBreak(accessToken: string, signal?: AbortSignal): Promise<DefinitiveEmployeeState> {
    return transition("end_break", accessToken, signal);
}
